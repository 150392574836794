'use strict';
import axios from "axios";

export default {
    state: {
        deliveryCompanies: [],
        calculateResults: [],
        calcValidationErrors: [],
    },
    mutations: {
        setterDeliveryCompanies(state, data) {
            state.deliveryCompanies = data;
        },
        setterCalculateResults(state, result) {
            if (Array.isArray(result)) {
                state.calculateResults = [...state.calculateResults, ...result];
            } else {
                state.calculateResults.push(result);
            }
        },
        setValidationErrors(state, errors) {
            state.calcValidationErrors = errors;
        },
        clearCalculateResults(state) {
            state.calculateResults = [];
        },
        clearValidationErrors(state) {
            state.calcValidationErrors = [];
        }
    },
    getters: {
        getDeliveryCompanies: state => state.deliveryCompanies,
        getCalculateResults: state => state.calculateResults,
        getValidationErrors: state => state.calcValidationErrors,
    },
    actions: {
        async getDeliveryCompaniesForParcel(context, payload) {
            const url = `?parcel_id=${payload.parcelId}&address_id=${payload.addressId}`;
            const response = await axios.get(`/user-dashboard/packages/parcel-delivery-companies${url}`);
            context.commit('setterDeliveryCompanies', response.data);
        },
        async getCalculateResults(context, data) {
            const source = new EventSource(`/calculate-rate?${new URLSearchParams(data).toString()}`);

            const processMessages = new Promise((resolve, reject) => {
                source.onmessage = (event) => {
                    try {
                        const result = JSON.parse(event.data);
                        context.commit('setterCalculateResults', result);
                    } catch (e) {
                        console.error("Failed to parse incoming data:", e);
                    }
                };

                source.addEventListener('complete', () => {
                    source.close();
                    resolve();
                });

                source.addEventListener('error', (event) => {
                    console.error("SSE connection error or closed unexpectedly.");
                    source.close();

                    try {
                        const errors = JSON.parse(event.data);
                        context.commit('setValidationErrors', errors.errors);
                        reject(new Error("SSE connection error"));
                    } catch (err) {
                        console.error("Error parsing validation errors:", err);
                        reject(new Error("SSE connection error, unable to parse error data"));
                    }
                });
            });

            try {
                await processMessages;
            } catch (error) {
                console.error("Error in SSE process:", error);
                throw error;
            }
        }
    },
};
